<template>
  <div class="footer">
    <div class="content">
      <div class="flex-between">
        <div>
          <p class="hotline">客服热线：</p>
          <p class="telephone">010-533 00337（7*24小时）</p>
        </div>
        <div class="center">
          <p>人力资源：zhaopin@xinranzixun.com<br />受理中心：slzx@xinranzixun.com</p>
          <p>北京总部：北京市昌平区沙河镇昌平路99号C座3221室(路庄桥西)<br />邮编：100022</p>
        </div>
        <div class="right">
          <img class="code"
               src="../components/img/code.png"
               alt="">
          <p>扫一扫，关注公众号</p>
        </div>
      </div>
      <p class="text-center info">©Copyright 2012-2021 xinranzixun.com Inc.All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备13051811号-3</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.footer {
  max-width: 1920px;
  background: #0b2840;
  color: #fff;
  padding: 60px 0 18px;
  font-size: 14px;
  line-height: 28px;
  .flex-between {
    margin-bottom: 100px;
    .hotline {
      font-size: 18px;
    }
    .telephone {
      font-size: 30px;
      margin: 16px 0;
    }
    .center > p {
      margin-bottom: 15px;
    }
    .right > p {
      margin-top: 6px;
    }
  }
  .info {
    font-size: 16px;
  }
}
</style>
